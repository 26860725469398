.mssg-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  background: rgba(255,255,255,.4);
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s
}

.mssg-link:hover {
  background: rgba(255, 255, 255, 0.8);
  -webkit-box-shadow: 0 4px 40px rgba(255, 255, 255, 0.35);
  box-shadow: 0 4px 40px rgba(255, 255, 255, 0.35)
}
