.square-style { 
  border-style: solid;
  border-radius: 4px;
  background-position: center center;
  border-color: transparent;
  box-shadow: 0px 0px 10px 5px rgb(197 206 219 / 10%);
  text-decoration: none !important;
  transition: .5s linear;
  aspect-ratio: 1 / 1; 
  overflow: hidden;
}

.square-style:hover {
  /* background-color: #D8BFD8; */
  background-color: #F3F3F3;
  opacity: 0.9;
  aspect-ratio: auto; 
  /* transition: .3s linear; */
}

.content{
  transition: .3s linear;
  /* overflow: visible; */
}

.square-style:hover .content
{
  transform: scale(1.1);
}

.long-text {
  /* display: none; */
  visibility: hidden;
  opacity: 0;  
}

.short-text {
  /* display: block; */
  visibility: visible;
  opacity: 1;
}

.square-style:hover .long-text {
  /* display: block; */
  visibility: visible;
  opacity: 1;
  transition: .5s linear;
}

.square-style:hover .short-text {
  /* display: none; */
  visibility: hidden;
  opacity: 0;  
  height: 0;
  transition: .1s linear;
}
