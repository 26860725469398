.square-style { 
  border-style: solid;
  border-radius: 4px;
  background-position: center center;
  border-color: transparent;
  box-shadow: 0px 0px 10px 5px rgb(197 206 219 / 10%);
  text-decoration: none !important;
  transition: .5s linear;
  aspect-ratio: 1 / 1; 
  overflow: hidden;
  background-color: red;
  color: white;
}

.square-style:hover {
  /* background-color: #D8BFD8; */
  opacity: 0.9;
}

.content{
  transition: .3s linear;
  /* overflow: visible; */
}

.square-style:hover .content
{
  transform: scale(1.1);
}
