.slider-container {
  background-color: "#2E004B";
  font-size: 2.0em;
}

.square-style {
  position: relative;
  /* border-style: solid;
  border-radius: 4px; */
  background-position: center center;
  border-color: transparent;
  /* box-shadow: 0px 0px 10px 5px rgb(197 206 219 / 10%); */
  /* text-decoration: none !important; */
  transition: .5s linear;
  /* aspect-ratio: 1 / 1; */
  /* overflow: hidden; */
  color: white;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0.0;
  transition: .5s ease;
  background-color: #273746;
}

.square-style:hover .overlay {
  opacity: 0.8;
}

.textbox {
  position: absolute;
  top: 80%;
  left: 0;
  color: white;
  font-size: 14px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  text-align: left;
  margin-left: 24px;
  margin-right: 24px;
  transition: .5s ease;
  opacity: 0;
}

.textbox-title {
  font-size: 120%;
  line-height: 125%;
}

.square-style:hover .textbox {
  opacity: 1;
  top: 50%;
}

.my-img {
  transition: 0.5s all ease-in-out;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.square-style:hover .my-img {
  transform: scale(1.15);
}