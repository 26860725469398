body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.screen-block {
  width: 100%;
  /* height: 100vh;   */
  /* max-height: 80vh; */
}

.image-fit {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.carousel-caption-my {
  position: absolute;
  top: 50%;
  bottom: auto;
  transform: translate(0%, -50%);  
  right: auto;
  left: auto;
  text-align: left;
  padding-left: 5%;
  
  /* align-items:center; */
} 
 
.section-title {
  color: #2E004B;
}

.u-go-to-v1 {
  width: 3.57143rem;
  height: 3.57143rem;
  display: block;
  border-radius: 50%;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 15%);
  transition: .3s ease-out;
  z-index: 11;
  position: fixed;
  bottom: 15px;
  left: 15px;
  background-color: rgba(255,255,255,0.7) !important;
}

.u-go-to-v1:before {
  content: '';
  position: absolute;
  top: calc(50% - 4px);
  left: calc(50% - 5px);
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  width: 12px;
  height: 12px;
  border: 2px solid transparent;
  border-color: inherit;
  border-bottom: 0;
  border-right: 0;
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
